.about-content {
  margin-top: 30px;
  color: #43C19F;
  font-size: 1.3rem;
}

.resume {
  position: absolute;
  padding-left: 1rem;
  justify-content: left;
}

.resume-link {
  color: #43C19F;
  font-weight: bold;
}

.resume-link:hover {
  color: #43C19F;
}

.lets-connect {
  background-color: #23585E;
  padding: 1rem;
  font-size: 1.3rem;
}

.skills-header {
  background-color: #23585E;
  padding: 1rem;
  font-size: 1.3rem;
  /* margin-top: 3rem; */
}

.certification-header {
  background-color: #23585E;
  padding: 1rem;
  font-size: 1.3rem;
  margin-top: 3rem;
}

.certification-container {
  margin-top: 1rem;
}

.certificate-title-sections {
  height: 120px;
  line-height:20px;
}

.certification-image {
  height: 400px;
  width: 600px;
}

.tensorflow-icon {
  height: 30px;
  width: 30px;
}

.connect-form {
  width: 100%;
}

.connect-input {
  width: 100%;
  color: #43C19F;
  border: none;
}

.connect-input::placeholder {
  color: #43C19F;
}

.message {
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
  color: #43C19F;
  border: none;
}

.file-about-icon {
  margin-right: 20px;
}

.margin-reset {
  margin-bottom: 0;
}

.left-justification {
  text-align: start;
  padding-left: 1rem;
}

#lets-connect-content {
  margin-top: 3rem;
}

.connect-btn {
  /* padding-left: 0; */
  color: #43C19F;
}

.connect-btn:hover {
  color: #43C19F;
}


/* Projects Section */

.projects-intro-container {
  margin-top: 10rem;
}

.projects-intro {
  background-color: #23585E;
  padding: 1rem;
  font-size: 1.3rem;
  color: #43C19F;
}

@media screen and (max-width: 991px) {
  .about-content {
    font-size: 3rem;
  }

  .lets-connect {
    font-size: 3rem;
  }

  .skills-header {
    font-size: 3rem;
  }
  
  .certification-header {
    font-size: 3rem;
  }

  .headshot {
    display: none;
  }

  .certificate-title-sections {
    margin-top: 3rem;
    height: 200px;
    line-height: 40px;
  }

  .tensorflow-icon {
    height: 60px;
    width: 60px;
  }
}
