.page-container {
  width: 100%;
  margin: auto;
  padding-top: 140px;
  height: 100%;
  overflow-y: hidden;
  background-color: #193549;
}

.page-title {
  background-color:#23585E;
  width: 80%;
  margin: auto;
  color: #43C19F;
  text-align: left;
  padding: 1rem;
  font-size: 1.9rem;
  font-weight: bold;
}

.page-picker-container {
  width: 80%;
  margin: auto;
  text-align: left;
}

@media screen and (max-width: 980px) {
  .page-container {
    padding-top: 200px;
  }

  .page-title {
    width: 90%;
    font-size: 3rem;
  }

  .page-picker-container {
    width: 90%;
    font-size: 1.8rem;
  }
}