.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  padding: 0 30px;
  background-color: #43C19F;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: inherit;
  height: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  overflow: hidden;
}

.logo {
  color: #193549;
  justify-self: start;
  padding: 0;
  margin: 0;
  height: 100%;
  
}

.logo-links {
  padding: 0;
  font-size: 1.5rem;
  color: #304749;
  text-decoration: none;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}

.logo-links:hover {
  color: #23585E;
}

.nav-menu {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-links {
  padding: 0;
  color: #304749;
  text-decoration: none;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  transition: background-color 1.5s;
}

.nav-links i {
  padding-right: 10px;
}

.nav-links:hover {
  background-color: #3BA78F;
  color: #304749;
  border-radius: 4px;
  transition: background-color .8s;
}

.fa-bars, .fa-times {
  color: #304749;
}

.menu-icons {
  display: none;
}

.nav-links-mobile {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #3BA78F;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 500;
  color:#304749;
  text-decoration: none;
}

.nav-links-mobile:hover {
  color: #304749;
}

@media screen and (max-width: 980px) {
  .navigation-container {
    z-index: 9999;
    height: 130px;
    font-size: 2rem;
  }

  .nav-menu {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(25, 53, 73, 0.75);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.15s ease-in-out;
  }

  .logo-links {
    position: relative;
    font-size: 2.5rem;
    color: #304749;
    top: 2rem;
  }

  .nav-menu.active {
    left: 0%;
  }

  .nav-links:first-child {
    margin-top: 30px;
  }

  .nav-links {
    display: block;
    widows: 100%;
    padding: 2rem 0;
    color: #0cd1a6;
    font-weight: bold;
  }

  .nav-links:hover {
    background-color: rgba(121, 183, 224, 0.25);
    backdrop-filter: blur(20px);
  }

  .menu-icons {
    display: block;
  }

  .nav-links-mobile {
    padding: 1.5rem;
    display: block;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    width: 80%;
    margin: auto;
  }
  
}