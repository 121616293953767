.hide {
  visibility: hidden;
}

@media only screen and (max-width: 991px) {
  .card {
    margin-top: 100px;
  }

  .card-image {
    height: 450px;
    width: 100%;
  }

  .card-title {
    font-size: 5rem;
  }

  .card-text {
    margin-top: 30px;
    font-size: 3rem;
  }

  .read-more {
    display: none;
  }

  .card-action-buttons {
    height: 100px;
  }

  .hide {
    visibility: hidden;
    margin-top: 10px;
    margin-left: 400px;
    width: 300px;
    background-color: rgba(35, 111, 199, 0.829);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }

  .simulator-btn:hover .hide {
    visibility: visible;
  }

  .simulator-btn {
    height: 100%;
    width: 30%;
    line-height: 80px;
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffff;
    margin-left: 3rem;
  }

  .custom-project-btn {
    height: 100%;
    width: 30%;
    line-height: 80px;
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffff;
    
  }
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .card:hover .card-image {
    opacity: .1;
  }
  
  .card:hover .card-title {
    visibility: visible;
    color: #55bbeb;
  }
  
  .card-title {
    position: absolute;
    visibility: hidden;
    font-size: 2rem;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card-text {
    position: relative;
    overflow: hidden;
    max-height: 20px;         
    transition: max-height .5s;  
  }

  .card-title:hover {
    cursor: default;
  }

  .text-height-in {
    max-height: 50px;
  }

  .text-height-out {
    max-height: 350px;
  }

  .card-image {
    height: 250px;
    width: 395px;
  }

  .collapse-text {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: height 2s ease-in; 
  }

  .read-more {
    margin: 0 auto;
    text-align: left;
    cursor: pointer;
    color:rgb(212, 116, 26);
    margin-bottom: 20px;
  }

  .hide {
    visibility: hidden;
    margin-top: 10px;
    margin-left: 220px;
    width: 120px;
    background-color: rgba(35, 111, 199, 0.829);
    color: #fff;
    text-align: center;
    border-radius: 6px;
  
    position: absolute;
    z-index: 1;
  }

  .simulator-btn:hover .hide {
    visibility: visible;
  }

  .simulator-btn {
    margin-left: 3rem;
  }
}

@media only screen and (min-width: 1100px) {
  .card:hover .card-image {
    opacity: .1;
  }
  
  .card:hover .card-title {
    visibility: visible;
    color: #55bbeb;
  }
  
  .card-title {
    position: absolute;
    visibility: hidden;
    height: 80px;
    font-size: 2rem;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card-text {
    position: relative;
    overflow: hidden;

    max-height: 40px;                /* set this to the height your first row would be */
    transition: max-height 2s ease-out;  
  }

  .card-title:hover {
    cursor: default;
  }

  .text-height-out {
    max-height: 350px;
  }

  .text-height-in {
    max-height: 50px;

  }

  .card-image {
    height: 250px;
    width: 150;
  }

  .collapse-text {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: height 2s ease-in; 
  }

  .read-more {
    margin: 0 auto;
    text-align: left;
    cursor: pointer;
    color:rgb(212, 116, 26);
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .hide {
    visibility: hidden;
    margin-top: 10px;
    margin-left: 200px;
    width: 120px;
    background-color: rgba(35, 111, 199, 0.829);
    color: #fff;
    text-align: center;
    border-radius: 6px;
  
    position: absolute;
    z-index: 1;
  }

  .simulator-btn:hover .hide {
    visibility: visible;
  }

  .simulator-btn {
    margin-left: 1rem;
  }
} 



/* Common CSS */

.card {
  background-color: #1694ee3a;
  border-radius: 3%;
}

.card-box-shadow {
  box-shadow: 5px 5px 5px rgb(41, 186, 235);
  transition: box-shadow 1s ease-in-out;
}

.simulator-btn {
  font-weight: bold;
  color: #304749;
  background-color: #43C19F;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: default;
  text-decoration: none;
  /* pointer-events: none; */
}

.simulator-btn:hover {
  background-color: #304749;
  color: #43C19F;
}

.custom-project-btn {
  font-weight: bold;
  color: #304749;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: #43C19F;
  padding-left: 1rem;
  text-decoration: none;
}

.custom-project-btn:hover {
  background-color: #304749;
  color: #43C19F;
}

.card-image {
  border-radius: 13px 13px 0px 0px;
}

.button-hidden {
  visibility: hidden;
}

.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
  animation: dot 1.3s infinite;
  animation-delay: 0.0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}