
/* Work Body Section */

.table {
  border: none;
  text-align: left;
}

.work-container {
  padding-left: 1rem;

}

.project-list {
  text-align: left;
  font-size: 1.5rem;
}

.project-link {
  text-decoration: none;
  color: #43C19F;
  font-weight: bold;
  font-size: 1.8rem;
}

.project-link:hover {
  color: #43C19F;
}

.fa-up-right-from-square {
  padding-left: 15px;
  font-size: 1.2rem;
}

.project-section {
  border: none;
  padding: 2rem 2rem 0rem 0 !important;
}

.project-list {
  list-style-type: none;
  color: #43C19F;
  margin-bottom: 0;
  padding-left: 0;
  /* padding: 1rem 2rem 2rem 0 !important; */
}

.project-list li {
  margin-bottom: 1rem;
}

.project-list-content, .project-section-title {
  text-align: left;
}

.loader-container {
  width: 100%;
  height: 50vh;
  position: relative;
  background: rgba(10, 21, 29, 0.834)
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
  z-index: 1;
  color: #ffff;
  font-size: 2rem;
  font-weight: bold;
}

.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
  animation: dot 1.3s infinite;
  animation-delay: 0.0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@media screen and (max-width: 980px) {
  .project-link {
    font-size: 2.8rem;
  }

  .project-list {
    font-size: 2rem;
  }
}